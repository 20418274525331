import request from '@/utils/request.js';
//获取省份 城市  地区
export function getProvince() {
    return request().get('/api/base/v1/areaselect/province/find')
}
export function getCity() {
    return request(Code).get(`/api/base/v1/areaselect/city/find?provinceCode=${Code}`)
}
export function getArea() {
    return request(Code).get(`/api/base/v1/areaselect/area/find?cityCode=${Code}`)
}

//获取任务管理列表信息
export function masterList(params) {
    return request().post(`/api/taskhall/v1/taskApp/listTask`, {
        ...params,
    })
}
//新建/编辑任务-基础信息
export function editTaskInfo(params) {
    return request().post(`/api/taskhall/v1/taskApp/editTaskInfo`, {
        ...params,
    })
}
//任务详情
export function getTask(id, fun) {
    return request().get(`/api/taskhall/v1/taskApp/getTask?id=${id}&fun=${fun}`)
}
//新建/编辑任务-执行配置
export function editTaskPerformCon(params) {
    return request().post(`/api/taskhall/v1/taskApp/editTaskPerformCon`, {
        ...params,
    })
}
//发布任务
export function publishTask(id) {
    return request().post(`/api/taskhall/v1/taskApp/publishTask?id=${id}`)
}

