
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  props: {
    formState: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    link() {
      this.$router.push("/taskHall/list");
    },
  },
});
