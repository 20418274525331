
import { defineComponent, createVNode } from "vue";
import moment from "moment";
import { editTaskInfo } from "@/api/taskManagement/taskManagement.js";
import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {},
  props: {
    formState: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dates: [],
      rules: {
        taskTitle: [
          {
            required: true,
            validator: (this as any).$store.state.currency.trimF,
            message: "请输入任务名称",
            trigger: "blur",
          },
        ],
        typeName: [
          {
            required: true,
            message: "请选择任务类型",
            trigger: "blur",
          },
        ],
        taskDescription: [
          {
            required: true,
            validator: (this as any).$store.state.currency.trimF,
            message: "请输入任务说明",
            trigger: "blur",
          },
        ],
        taskRequire: [
          {
            required: true,
            validator: (this as any).$store.state.currency.trimF,
            message: "请输入任务要求",
            trigger: "blur",
          },
        ],
        serviceFee: [
          {
            required: true,
            validator: (this as any).$store.state.currency.trimF,
            message: "请输入服务费说明",
            trigger: "blur",
          },
        ],
        linkName: [
          {
            required: true,
            validator: (this as any).$store.state.currency.trimF,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        linkPhone: [
          {
            required: true,
            validator: (this as any).$store.state.currency.trimF,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        taskDimension: [
          {
            required: true,
            message: "请选择任务执行区域",
            trigger: "blur",
          },
        ],
        prjName: [
          {
            required: true,
            message: "请输入关联项目",
            validator: (this as any).$store.state.currency.trimF,
            trigger: "blur",
          },
        ],
        prjCode: [
          {
            required: true,
            message: "请输入关联项目编码",
            validator: (this as any).$store.state.currency.trimF,
            trigger: "blur",
          },
        ],
        rangePickerCarryOut: [
          {
            required: true,
            validator: async (rule, value) => {
              if (value === undefined) {
                return Promise.reject("请选择任务执行时间");
              } else if (value.length < 2) {
                return Promise.reject("请选择任务执行时间");
              }
            },
            trigger: "blur",
          },
        ],
        getEndTimePicker: [
          {
            trigger: "blur",
            validator: async (rule, value) => {
              if (
                value !== undefined &&
                this.formState.rangePickerCarryOut != undefined
              ) {
                let endTime = new Date(
                  moment(this.formState.rangePickerCarryOut[1]).format(
                    "YYYY-MM-DD 23:59:59"
                  )
                ).getTime();
                let signUpEnd = new Date(
                  moment(value).format("YYYY-MM-DD 23:59:59")
                ).getTime();
                if (endTime < signUpEnd) {
                  return Promise.reject(
                    "报名截止时间不可以晚于任务执行结束时间"
                  );
                }
              }
            },
          },
        ],
        receiveAudit: [
          {
            required: true,
            validator: async (rule, value) => {
              if (value !== 0 && value !== 1) {
                return Promise.reject("请选择");
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    moment,
    range(start, end) {
      const result: any = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    //更改日期配合设置禁用日期
    onCalendarChange(val) {
      (this as any).dates.value = val;
    },
    onOpenChange(open) {
      if (open) {
        (this as any).dates.value = [];
      }
    },
    //只能选择今天之后
    disabledDate(current) {
      return current && current <= moment().add(-1, "days");
    },
    // disabledRangeTime(moment, type) {
    // if (type === "start") {
    //   return {
    //     disabledHours: () => this.range(0, 60).splice(4, 20),
    //     disabledMinutes: () => this.range(30, 60),
    //     disabledSeconds: () => [55, 56],
    //   };
    // }
    //   return {
    //     disabledHours: () => this.range(0, 60).splice(20, 4),
    //     disabledMinutes: () => this.range(0, 31),
    //     disabledSeconds: () => [55, 56],
    //   };
    // },
    //点击下一步
    onSubmit(next = true) {
      (this as any).$refs.formRef.validate().then(() => {
        let startTime = moment(this.formState.rangePickerCarryOut[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        let endTime = moment(this.formState.rangePickerCarryOut[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
        let getEndTime = this.formState.getEndTimePicker
          ? this.formState.getEndTimePicker.format("YYYY-MM-DD 23:59:59")
          : null;
        editTaskInfo({
          id: this.$route.params.id == "new" ? null : this.$route.params.id,
          taskTitle: this.formState.taskTitle.trim(),
          typeCode: "CTT000ACTIVITY", //任务类型编码 尚未开发写死的
          typeName: "活动推广", //任务类型名称	尚未开发写死的
          prjName: this.formState.prjName.trim(),
          prjCode: this.formState.prjCode.trim(),
          taskDescription: this.formState.taskDescription.trim(),
          taskRequire: this.formState.taskRequire.trim(),
          serviceFee: this.formState.serviceFee.trim(),
          memo: this.formState.memo ? this.formState.memo.trim() : null,
          linkName: this.formState.linkName.trim(),
          linkPhone: this.formState.linkPhone.trim(),
          receiveAudit: this.formState.receiveAudit,
          getEndTime: getEndTime,
          startTime: startTime,
          endTime: endTime,
          taskDimension: this.formState.taskDimension, //任务执行区域（1：不限区域 2：终端任务 3：区域任务）尚未开发写死的
        }).then((res) => {
          if (res.data.success) {
            (this as any).$router.push(`/taskHall/created/${res.data.data}`);
            message.success("保存成功");
            this.$emit("baseDone", res.data.data, next);
          }
        });
      });
    },
    cancel() {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "离开此页面未保存的信息将会消失!",
        cancelText: "取消",
        okText: "离开",
        onOk() {
          that.$router.go(-1);
        },
      });
    },
    changeRadio() {
      (this as any).$refs.formRef.validateFields(["receiveAudit"]);
    },
  },
  mounted() {},
});
