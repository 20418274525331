
const listTen = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
import { defineComponent } from "vue";
import {
  QuestionCircleOutlined,
  RightOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import {
  editTaskPerformCon,
  publishTask,
} from "@/api/taskManagement/taskManagement.js";
import { message } from "ant-design-vue";
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    RightOutlined,
    PlusOutlined,
    DeleteOutlined,
  },
  props: {
    formState: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      rules: {
        performUrl: [
          {
            required: true,
            pattern: /^((https|http)?:\/\/)[^\s]+/,
            message: "请输入正确的推广URL",
            trigger: "blur",
          },
        ],
        callbackUrl: [
          {
            pattern: /^((https|http)?:\/\/)[^\s]+/,
            message: "请输入正确的回调URL",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    upperLevel() {
      this.$emit("upperLevel");
    },
    changeExtr() {
      (this as any).$refs.formRef.validateFields([
        "code0",
        "code1",
        "code2",
        "code3",
        "code4",
        "code5",
        "code6",
        "code7",
        "code8",
        "code9",
      ]);
    },
    // handleConfirmURL(rule,value,callBack){
    //   if (value) {
    //     let pattern = /^((https|http)?:\/\/)[^\s]+/;
    //     if (pattern.test(value)) {
    //       callBack()
    //     }else{
    //       callBack('请输入正确的url地址')
    //     }
    //   }
    // },
    save(type = "save") {
      let nowTime = new Date().getTime()
      // let startTime = new Date(this.formState.startTime).getTime(); //任务执行开始时间
      let endTime = new Date(this.formState.endTime).getTime(); //任务执行结束时间
      let getEndTime = new Date(this.formState.getEndTime).getTime(); //任务报名截止时间
      if (getEndTime<nowTime) {
         message.warning("步骤1:\"基础信息\"中的报名截止时间不能早于当前时间");
         return
      }
      if (endTime<nowTime) {
         message.warning("步骤1:\"基础信息\"中的任务执行结束时间不能早于当前时间");
         return
      }
      if (getEndTime>endTime) {
         message.warning("步骤1:\"基础信息\"中的报名截止时间不可以晚于任务执行结束时间");
         return
      }
      (this as any).$refs.formRef.validate().then(() => {
        var performUrlParam: any = "";
        let array = (this as any).formState.extraList.filter((el) => {
          return el.value.trim();
        });
        if (array.length) {
          performUrlParam = JSON.stringify(
            array.map((el) => {
              return { k: el.name, v: el.value };
            })
          );
        }
        editTaskPerformCon({
          id: this.$route.params.id,
          taskMemo: this.formState.taskMemo
            ? this.formState.taskMemo.trim()
            : null,
          publishPort: this.formState.publishPort,
          performPort: this.formState.performPort,
          performUrl: this.formState.performUrl.trim(),
          performUrlParam: performUrlParam,
          callbackUrl: this.formState.callbackUrl
            ? this.formState.callbackUrl.trim()
            : null,
          callbackUrlParam: "",
        }).then((res) => {
          if (res.data.success) {
            if (type !== "publish") {
              message.success("保存成功");
            }
            this.$emit("save");
            if (type === "publish") {
              publishTask(this.$route.params.id).then((res: any) => {
                if (res.data.success) {
                  message.success("保存并发布成功");
                  this.$emit("publish");
                }
              });
            }
          }
        });
      });
    },
    add() {
      if (this.formState.extraList.length == 10) {
        message.warning("最多支持10个扩展字段");
        return;
      }
      if (this.formState.extraList.length) {
        let item =
          this.formState.extraList[this.formState.extraList.length - 1];
        this.formState.extraList.push({
          name: "",
          value: "",
          code: "code" + (Number(item.code.replace("code", "")) + 1),
        });
      } else {
        this.formState.extraList = [];
        this.formState.extraList.push({
          name: "",
          value: "",
          code: "code0",
        });
      }
    },
    del() {
      this.formState.extraList.length = this.formState.extraList.length - 1;
    },
    computedRules() {
      let obj: any = {};
      listTen.forEach((item) => {
        let name = "code" + item;
        let indexName = Number(name.replace("code", ""));
        obj[name] = [
          {
            trigger: "blur",
            validator: async (rule, value) => {
              //校验名称不可重复
              let flag = -1;
              this.formState.extraList.forEach((item, index) => {
                let el = this.formState.extraList[indexName];
                if (
                  index < indexName &&
                  item.name &&
                  item.name.trim() == el.name.trim()
                ) {
                  flag = index;
                }
              });
              if (flag > -1) {
                return Promise.reject(`与扩展字段${flag + 1}的名称重复`);
              }
              //校验 必须同时有值和名称
              let el = this.formState.extraList[indexName];
              if (el.value.trim() == "" && el.name.trim() != "") {
                return Promise.reject(`请输入扩展字段${indexName + 1}的值`);
              }
              if (el.value.trim() != "" && el.name.trim() == "") {
                return Promise.reject(`请输入扩展字段${indexName + 1}的名称`);
              }
            },
          },
        ];
      });
      this.rules = {
        ...this.rules,
        ...obj,
      };
    },
  },
  mounted() {
    this.computedRules();
  },
});
