import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4db388e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center","margin-top":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, {
      model: _ctx.formState,
      rules: _ctx.rules,
      ref: "formRef",
      "label-col": { style: { width: '100px' } },
      class: "a_form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          label: "任务名称",
          name: "taskTitle"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _ctx.formState.taskTitle,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.taskTitle) = $event)),
              placeholder: "请输入",
              maxlength: 100,
              allowClear: "",
              size: "small"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "任务类型",
          name: "typeName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _ctx.formState.typeName,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.typeName) = $event)),
              placeholder: "请选择",
              allowClear: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "CTT000ACTIVITY" }, {
                  default: _withCtx(() => [
                    _createTextVNode("活动推广")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "关联项目",
          name: "prjName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _ctx.formState.prjName,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.prjName) = $event)),
              placeholder: "请输入",
              maxlength: 100,
              allowClear: "",
              size: "small"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "关联项目编码",
          name: "prjCode"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _ctx.formState.prjCode,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.prjCode) = $event)),
              placeholder: "请输入",
              maxlength: 50,
              allowClear: "",
              size: "small"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "任务说明",
          name: "taskDescription"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_textarea, {
              maxlength: 1000,
              value: _ctx.formState.taskDescription,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.taskDescription) = $event)),
              placeholder: "请输入",
              allowClear: ""
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "任务要求",
          name: "taskRequire"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_textarea, {
              maxlength: 1000,
              value: _ctx.formState.taskRequire,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.taskRequire) = $event)),
              placeholder: "请输入",
              allowClear: ""
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "服务费说明",
          name: "serviceFee"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_textarea, {
              value: _ctx.formState.serviceFee,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.serviceFee) = $event)),
              placeholder: "请输入",
              maxlength: 1000,
              allowClear: ""
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "备注",
          name: "memo"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_textarea, {
              value: _ctx.formState.memo,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.memo) = $event)),
              placeholder: "请输入",
              maxlength: 1000,
              allowClear: ""
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "联系人",
          name: "linkName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _ctx.formState.linkName,
              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.linkName) = $event)),
              placeholder: "请输入",
              maxlength: 50,
              allowClear: "",
              size: "small"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "联系电话",
          name: "linkPhone"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _ctx.formState.linkPhone,
              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formState.linkPhone) = $event)),
              placeholder: "请输入",
              maxlength: 50,
              allowClear: "",
              size: "small"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "任务执行时间",
          name: "rangePickerCarryOut"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_range_picker, {
              style: {"width":"100%"},
              format: "YYYY-MM-DD",
              value: _ctx.formState.rangePickerCarryOut,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.rangePickerCarryOut) = $event)),
              onCalendarChange: _ctx.onCalendarChange,
              onOpenChange: _ctx.onOpenChange,
              disabledDate: _ctx.disabledDate,
              allowClear: ""
            }, null, 8, ["value", "onCalendarChange", "onOpenChange", "disabledDate"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "报名截止时间",
          name: "getEndTimePicker"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_date_picker, {
              style: {"width":"100%"},
              showToday: false,
              value: _ctx.formState.getEndTimePicker,
              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formState.getEndTimePicker) = $event)),
              format: "YYYY-MM-DD",
              "disabled-date": _ctx.disabledDate
            }, null, 8, ["value", "disabled-date"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "是否报名审核",
          name: "receiveAudit"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_group, {
              name: "radioGroup",
              value: _ctx.formState.receiveAudit,
              "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formState.receiveAudit) = $event)),
              onChange: _ctx.changeRadio,
              disabled: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio, { value: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 是 ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_radio, { value: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 否 ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "任务执行区域",
          name: "taskDimension"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _ctx.formState.taskDimension,
              "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formState.taskDimension) = $event)),
              placeholder: "请选择",
              allowClear: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "1" }, {
                  default: _withCtx(() => [
                    _createTextVNode("不限区域")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        style: {"width":"120px"},
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 取消 ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.onSubmit(false))),
        style: {"width":"100px","margin":"0 40px"},
        loading: this.$store.state.loadingStatus
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 保存 ")
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _ctx.onSubmit,
        style: {"width":"120px"},
        loading: this.$store.state.loadingStatus
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 保存并下一步 ")
        ]),
        _: 1
      }, 8, ["onClick", "loading"])
    ])
  ]))
}